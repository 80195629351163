<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="layout-column rows">
        <div class="row row-1 layout-column layout-align-start-end">
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="text-right parallax-item parallax-relative"
            :show="showTitle"
          ></section-title>
        </div>
        <div class="row row-2 layout-row cols layout-align-center-start">
          <div class="col-1 layout-row layout-align-end-center col-padding">
            <video-box
              id="video"
              class="parallax-item parallax-relative section-videobox"
              data-depth="1.9"
              :show="showVideoBox"
              :data="data.boxes.video"
              :timescale="0.95"
              :timescaleReverse="1.5"
            >
            </video-box>
            <info-modal-box
              id="intro-box"
              :show="showInfoBoxes"
              class="parallax-item parallax-relative section-infobox"
              data-depth="2.5"
              :data="data.boxes.info_1"
              :timescale="0.9"
            >
            </info-modal-box>
          </div>
          <div class="col-2 layout-align-start-start col-padding layout-column">
            <quote
              :data="data.boxes.quote"
              :fontSize="1.7"
              :maxWidth="'26em'"
              :overlayOpacity="0.7"
              :show="showQuote"
              data-depth="3.8"
              class="parallax-item parallax-relative"
              :timescale="0.7"
              :splitLines="true"
            >
            </quote>
          </div>
        </div>
      </div>
    </div>
    <div class="container fluid absolute z-index-0">
      <decal
        id="decal-gwf"
        class="absolute"
        :show="showBackground"
        :image="data.images.gwf"
        :customTimeline="timelines.timelineGWF"
        :parallaxDepth="1"
      ></decal>
    </div>
    <div class="container fluid absolute z-index-1">
      <decal
        id="decal-leopard"
        class="absolute"
        :show="showBackground"
        :image="data.images.leopard"
        :customTimeline="timelines.timelineLeopard"
      ></decal>
    </div>
    <div class="container absolute z-index-1">
      <decal
        id="decal-girl"
        class="absolute"
        :show="showDecal"
        :image="data.images.girl"
        :customTimeline="timelines.timelineLina"
        :parallaxDepth="2.9"
        :caption="caption"
      ></decal>
    </div>

    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
    <modal-lightbox></modal-lightbox>
    <modal-video></modal-video>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Decal from "@/components/ui/Decal.vue";
import InfoModalBox from "@/components/ui/InfoModalBox.vue";
import ModalLightbox from "@/components/ui/ModalLightbox.vue";
import ModalVideo from "@/components/ui/ModalVideo.vue";
import VideoBox from "@/components/ui/VideoBox.vue";
import Quote from "@/components/ui/Quote.vue";

import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-3-4",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Decal,
    InfoModalBox,
    ModalLightbox,
    ModalVideo,
    VideoBox,
    Quote,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 200],
        ["showTitle", 400],
        ["showQuote", 450],
        ["showInfoBoxes", 470],
        ["showDecal", 0],
        ["showBackground", 140],
        ["showVideoBox", 1200],
      ],
      timelines: {
        timelineLeopard: {
          duration: 1.2,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            x: "-=18%",
            scale: "1",
          },
        },
        timelineGWF: {
          duration: 1.2,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            x: "+=18%",
            scale: "1",
          },
        },
        timelineLina: {
          duration: 1.2,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            y: "+=7%",
            scale: "1",
          },
        },
      },
      caption: {
        desc: "Preparing for the future",
        sub: "image:<a href='https://store.londolozi.com/image/93857' target='_blank'> GWF</a> by Amanda Ritchie",
      },
    };
  },
};
</script>

<style scoped>
.row-2 {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}
.col-2 {
  padding-left: 0;
  padding-right: 1.1rem;
}

.section-title {
  margin-right: 19%;
  max-width: none;
}
.section-quote {
  margin-top: 0;
}
/deep/ .section-quote .quote-quote {
  width: 100% !important;
  max-width: 26em;
}
#intro-box {
  z-index: 2;
  margin: 2rem 0 0 0;
}
/deep/ #intro-box .info-box {
  background: rgba(255, 255, 255, 0.85);
}
#video {
  margin-top: 10rem;
  z-index: 1;
  margin-right: -2.1rem;
}
.container.absolute {
  z-index: 1;
}
.container.fixed {
  z-index: 0;
}
#decal-leopard {
  left: -1% !important;
  top: 5% !important;
  max-width: 50%;
}
/deep/ #decal-leopard img {
  height: auto;
}
#decal-girl {
  left: auto;
  right: 16%;
  bottom: -1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0.5rem 0.8rem 1.9rem);
  width: 100%;
}
/deep/ #decal-girl img {
  height: auto;
  width: 100%;
  width: 650px;
  max-width: 43.5vw;
  min-width: 440px;
}

/deep/ #decal-girl .media-caption {
  min-width: 13.5em;
}

#decal-gwf {
  right: -10%;
  bottom: 9%;
  margin: 0;
  top: auto;
  min-width: 380px;
  width: 680px;
  max-width: 40vw;
}

/deep/ #decal-gwf img {
  height: auto;
  width: 100%;
}

/deep/ #intro-box p {
  margin-right: 1em;
  margin-left: 1em;
}
/deep/ .video-box-image img {
  filter: none;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
  .section-title {
    margin-right: 15%;
  }
  #decal-girl {
    right: 10%;
  }
  #decal-gwf {
    width: 550px;
  }
  .section-quote {
    font-size: 0.95rem;
  }
  /deep/ #decal-girl img {
    width: 665px;
  }
}

@media screen and (max-width: 1440px) {
  /deep/ #decal-girl .media-caption {
    min-width: 15.5em;
  }
  .section-quote {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1200px) {
  .section-title {
    margin-right: 13%;
  }
  #intro-box {
    z-index: 2;
    margin: 0;
  }
  #video {
    margin-top: 6rem;
    z-index: 1;
    margin-right: -5.5rem;
  }
  /deep/ #intro-box p {
    margin-right: 0;
    margin-left: 0;
  }
  .row-2 {
    margin-bottom: 3rem;
  }
  .section-quote {
    font-size: 0.8rem;
  }
  /deep/ .section-quote .quote-quote {
    max-width: 20.5em;
  }
  /deep/ #decal-girl img {
    max-width: 42.5vw;
  }
}

@media screen and (max-width: 1023px),
  screen and (min-width: 992px) and (max-width: 1024px) and (orientation: portrait) {
  .row-1,
  .row-2 {
    padding: var(--col-padding);
  }
  .row-2 {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-bottom: 14rem;
  }
  .col-1 {
    width: 100%;
    order: 2;
    justify-content: center;
    padding: 0;
    align-items: flex-start;
  }
  .col-2 {
    width: 100%;
    order: 1;
    align-items: center;
  }
  #video {
    margin-right: -2.1rem;
    margin-top: 3rem;
  }
  /deep/ .section-quote .quote-quote {
    max-width: 100%;
  }
  /deep/ .section-quote .quote-overlay {
    padding-right: 0;
  }
  .section-quote {
    max-width: 45em;
    font-size: 0.8rem;
    margin: 0.5rem 0 1rem 20%;
  }
  #decal-leopard {
    left: -1% !important;
    top: 2% !important;
    max-width: 55%;
  }
  .section-title {
    max-width: 4.55em;
    margin-right: 20%;
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  /deep/ #decal-girl img {
    min-width: 400px;
  }
  /deep/ #decal-girl .media-caption {
    display: none !important;
  }
  .col-2 {
    padding: 0;
    max-width: 100%;
  }
  .section-quote {
    padding-right: var(--col-padding-adjust);
  }
}

@media screen and (max-width: 639px) {
  .section-quote {
    margin-left: 0;
    font-size: 0.7rem;
    padding-left: 2rem;
    max-width: 42.5em;
  }
  .section-title {
    margin-right: 16%;
  }
  #video {
    margin-right: -5rem;
  }
  #decal-gwf {
    min-width: 340px;
    bottom: 6%;
  }
  #decal-leopard {
    top: 3.5rem !important;
    max-width: 58%;
  }
}
@media screen and (max-width: 580px) {
  .section-quote {
    font-size: 0.65rem;
  }
  .col-1 {
    flex-direction: column;
    align-items: center;
  }
  #video {
    margin: 0;
    order: 2;
  }
  #intro-box {
    margin: 0;
    order: 1;
  }
  #decal-gwf {
    bottom: 35%;
  }
  #decal-leopard {
    left: -5% !important;
    top: 4rem !important;
    max-width: 65%;
  }
  .section-title {
    margin-right: 12%;
  }
  /deep/ #decal-girl img {
    min-width: 380px;
  }
}

@media screen and (max-width: 520px) {
  #decal-girl {
    right: auto;
    left: 10%;
  }
}

@media screen and (max-width: 479px) {
  .row-2 {
    margin-bottom: 14rem;
  }
  #decal-gwf {
    min-width: 260px;
    max-width: 60vw;
    bottom: 40%;
    right: -15%;
  }

  .section-title {
    margin-right: 4rem;
  }
  #decal-leopard {
    left: -15% !important;
    top: 5rem !important;
    min-width: 300px;
  }
  /deep/ #decal-girl img {
    min-width: 350px;
  }
}

@media screen and (max-width: 438px) {
  .section-title {
    margin-right: 3rem;
  }
}

@media screen and (max-width: 420px) {
  #decal-leopard {
    left: -20% !important;
    top: 4rem !important;
    min-width: 300px;
  }
  /deep/ #video .info-box-wrap {
    margin-top: 0;
  }
  #decal-gwf {
    bottom: 43%;
  }
  .section-quote {
    max-width: 34em;
  }
}

@media screen and (max-width: 390px) {
  #decal-leopard {
    left: -28% !important;
    top: 3.8rem !important;
  }
  /deep/ #decal-girl img {
    min-width: 340px;
  }
}
</style>
